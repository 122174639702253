import "./App.css";
import vassoura from "./assets/vassoura.jpg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={vassoura} alt="vassoura" />
        <h1>coming soon!</h1>
      </header>
    </div>
  );
}

export default App;
